import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import ReactGA from 'react-ga4'

const setUserId = (userid) => {
  ReactGA.set({ userId: userid })
}

const TrackUserId = (props) => {
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const userid = isAuthenticated ? authState?.accessToken?.claims?.sub : undefined;

    useEffect(() => {
        const trackUser = () => {
            console.log("userid", userid);
            if (userid) {
                ReactGA.event({category: "Identifier", action: "loggedOn", label: "true"});
                setUserId(userid);
            } else {
                ReactGA.event({category: "Identifier", action: "notYetLoggedOn", label: "true"});
                setTimeout(trackUser, 5000);
            }
        };

        trackUser();
    }, [ userid ]);

    return null;
}

export default TrackUserId;
