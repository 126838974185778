import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react'

const ciEquals = (a, b) => {
    return a && b && a.localeCompare(b, undefined, {sensitivity: 'base'}) === 0;
}

export default function useRoleManagement() {
    const [ roles, setRoles ] = useState({roles: []});
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const token = authState?.accessToken?.accessToken;

    useEffect(() => {
        let baseUrl = process.env.REACT_APP_ROLE_MANAGEMENT_BASE_URL;
        if (!baseUrl) {
            console.log("No baseUrl found? Setting default ...");
            baseUrl = "https://api.eu.customs-inventory-test.nikecloud.com/role/list";
        }

        const fetchRoles = async () => {
            if (!isAuthenticated) {
                return ;
            }
            console.log("Fetching roles from ", baseUrl);
            setRoles(await fetch(baseUrl, {
                    method: "GET",
                    headers: {
                        "accept": "application/json",
                        "authorization": "Bearer " + token
                    }
                }).then(response => response.json()));
        }

        fetchRoles();
    }, [setRoles, isAuthenticated, token ]);

    return {
        roles: roles.roles,
        hasRole: (role : String) => {
            if (roles && role) {
                for (const r of roles.roles) {
                    if (ciEquals(r, role)) {
                        return true;
                    }
                }
            }
            return false;
        }
    };
}
