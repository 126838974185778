import ReactGA from 'react-ga4'

const initializeGa = () => {
  let accountId = process.env.REACT_APP_GA4_ACCOUNTID;
  let environment = process.env.REACT_APP_GA4_ENVIRONMENT; 
  let instance = process.env.REACT_APP_GA4_INSTANCE;
  ReactGA.initialize(accountId);
  ReactGA.set({
    dimension1: environment,
    dimension2: instance,
    appName: 'CI ' + instance + ' @ ' + environment
  })
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  ReactGA.event({ category: 'open', action: 'Instance', label: instance });
  ReactGA.event({ category: 'open', action: 'Environment', label: environment });
}

export default initializeGa