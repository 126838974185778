import React from 'react'
import './LoginError.css'

export function LoginError(props: any) {
  return (
    <div className="LoginError">
      <header className="LoginError-header">
        <p className="LoginError-p">Something went wrong during the login</p>
        <p className="LoginError-p">
          Error: {props.error.name} [{props.error.errorCode}] - {props.error.message}
        </p>
      </header>
    </div>
  )
}
