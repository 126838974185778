import '@nike/eds/dist/index.css'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security, LoginCallback } from '@okta/okta-react'
import React, { useCallback } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { oktaAuth } from './config/OktaConfig'
import { LoginError } from './login-error/LoginError'
import { RequiredAuth } from './shared/AuthenticatedRoute'
import '@nike/eds/dist/index.css'
import { RequestOverview } from './pages/RequestOverview'
import { InventoryOverview } from './pages/InventoryOverview'
import { Simulation } from './pages/Simulation'
import { createBrowserHistory } from 'history'
import useRoleManagement from './shared/useRoleManagement'
import TrackUserId from './shared/analytics/identify'
import {FlowOverview} from "./pages/FlowOverview";

const history = createBrowserHistory()

const AppInSecureContext = () => {
    const roles = useRoleManagement();
    console.log("Roles for current user: ", roles);

    return  <>
                <TrackUserId />
                <Routes>
                    <Route element={<RequiredAuth/>}>
                        <Route path="/simulation/*" element={<Simulation history={history} roles={roles}/>} />
                        <Route path="/request-overview/*" element={<RequestOverview history={history} roles={roles}/>}/>
                        <Route path="/flow-overview/*" element={<FlowOverview history={history} roles={roles}/>}/>
                        <Route path="/inventory-overview/*" element={<InventoryOverview history={history} roles={roles}/>}/>
                        <Route path="/" element={<Navigate to="/inventory-overview"/>}/>
                    </Route>
                    <Route path="/implicit/callback" element={<LoginCallback errorComponent={LoginError}/>}/>
                </Routes>
            </>
}

const App = () => {
    const navigate = useNavigate()
    const restoreOriginalUri = useCallback(
        (_oktaAuth: any, originalUri: string) => {
            navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
        },
        [navigate]
    )

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <React.Fragment>
              <AppInSecureContext />
            </React.Fragment>
        </Security>
    );
}

export default App
