import 'normalize.css' //Keep this first!
import './styles/Fonts.css'
import './styles/Index.css'
import '@nike/eds/dist/index.css'
import React from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import initializeGa from './shared/analytics/ga-bootstrap'

import App from './App'

initializeGa();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
