import { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Outlet } from 'react-router-dom'

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const isAuthenticated = authState?.isAuthenticated

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, authState, isAuthenticated])

  return <Outlet></Outlet>
}
