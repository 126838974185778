import MicroFrontend from '../MicroFrontend'
import {Header} from '../shared/header/Header'
import {Footer} from '../shared/footer/Footer'
import {SideBar} from "../shared/header/SideBar";
import "./scrollable.css"

const verticalNavStyle = {
    height: "100vh"
}

export function InventoryOverview({history, roles}) {
    const {REACT_APP_INVENTORY_OVERVIEW_HOST: inventoryOverviewHost} = process.env
    return (
        <div>
            <div className="eds-flex" style={verticalNavStyle}>
                <div>
                    <SideBar roles={roles}/>
                </div>
                <div className="eds-flex--grow-1">
                    <Header isDark={true} roles={roles}/>
                    <div className="scrollable">
                        <MicroFrontend
                            history={history}
                            host={inventoryOverviewHost}
                            microFrontendUiName="InventoryOverview"/>
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    )
}
