import { AppHeader, Avatar } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import { useMemo } from 'react'

export function Header({roles, isDark}) {
    const { authState } = useOktaAuth()
    const initials = useMemo(() => {
        const name = authState?.accessToken?.claims?.sub
        return name ? getInitials(name) : '-'
    }, [authState])

    return (
        <AppHeader
            appName='Customs Inventory'
            isDark={isDark}
            avatarSlot={<Avatar initials={initials} size={36} />}
        />
    )
}

function getInitials(name: string) {
    const matchedFirstLetters = name.match(/(\b[A-Z])/g)
    if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
        // Fallback: take first letter
        return name[0]?.toUpperCase()
    }
    return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}
