import ReactGA from "react-ga4";

const LocationTracker = (props) => {
  for (const k in props) {
    let v = props[k];
    if (v !== undefined && v !== null && v.toString) {
      v = v.toString();
    }
    if (typeof v === "string") {
      ReactGA.event({category: "LocationTracker", action: k, label: v});
    }
  }

  return null;
}

export default LocationTracker;
