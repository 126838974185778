import { NikeApp } from '@nike/nike-design-system-icons'
import './Footer.scss'

export function Footer() {
  return (
    <div className="footer">
      <NikeApp
        title="Nike logo"
        color="black"
        width="56px"
        height="64px"
        style={{ marginTop: '6px' }}
      />
    </div>
  )
}
